<script>
</script>
<template>
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
    <div class="mb-3 font-bold text-3xl">
        <span class="text-900">Shop By </span>
        <span class="text-blue-600">Top Brands</span>
    </div>
    <div class="text-700 mb-6">Top Brands Under One Roof.</div>
    <div class="grid">
        <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <img src="../assets/brandGarmin.png" alt="brand" height="120" class="w-6 mr-2"/>
            </span>
            <div class="text-900 text-xl mb-3 font-medium uppercase">Gamin</div>
            <span class="text-700 line-height-3">Selling all garmin products.</span>
        </div>
        <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <img src="../assets/BrandTaxc.png" alt="brand" height="120" class="w-6 mr-2"/>
                
            </span>
            <div class="text-900 text-xl mb-3 font-medium uppercase">Tacx</div>
            <span class="text-700 line-height-3">Selling all tacx products.</span>
        </div>
        <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <img src="../assets/brandSaucony.jpg" alt="brand" height="120" class="w-6 mr-2"/>
                
            </span>
            <div class="text-900 text-xl mb-3 font-medium uppercase ">Saucony</div>
            <span class="text-700 line-height-3">Selling all saucony products.</span>
        </div>
       
    </div>
</div>

</template>