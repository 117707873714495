<script>
    export default {
        
    }
</script>

<template>
    <div class="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center">

        <div class="flex w-4 text-base gap-2"> 
            
            <div class="text-xl align-items-start px-3">
                <h4>Products</h4>
                <ul>
                <li>Garmin</li>
                <li>Tacx</li>
                <li>Saucony</li>

            </ul>
            </div>
            <div class="text-xl align-items-start px-3">
                <h4>Contact</h4> 
            <ul>
            <li>About Us</li>
        </ul>
            </div>
    </div>
    </div>
     <div class="bg-bluegray-900 text-gray-100 flex p-3 justify-content-end align-items-center">
        <span class="line-height-3">Copyright © 2023 BandBucket • All Rights Reserved.</span>
    </div> 

  

</template>

<style  scoped>
ul{
    list-style: none;
    padding-left: 0px;

}
li{
    font-size: medium;
    text-align: start;
    font-weight: 600;
    line-height: 1.2rem;
    cursor: pointer;
}

</style>