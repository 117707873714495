<template>
    <div class="flex justify-content-center align-item-center  not-found">
        <img src="../assets/404.jpg" alt="not found" />
    </div>
</template>
<style scoped>
img{
    width: 50%;
    height: 10%;
}
</style>